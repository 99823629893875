div .modal-header {
  background-color: #6159e6;
}

div .modal-title {
  color: #fff;
}

.apsis-client-card {
  margin-top: 20px;
  padding: 25px 30px;
  min-height: 285px;
}
