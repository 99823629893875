.courses .content {
  padding: 0px 100px 0 100px;
}

.courses .content h3 {
  font-weight: 400;
  font-size: 34px;
  color: #37517e;
}

.courses .content h4 {
  font-size: 20px;
  font-weight: 700;
  margin-top: 5px;
}

.courses .content p {
  font-size: 0.99rem;
  color: #848484;
}

.courses .img {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}
