div .team-modal-header {
  background-color: #6159e6;
}

div .team-modal-title {
  color: #fff;
}

a {
  font-weight: bolder;
  color: #6159e6
}

.card-team.user-card {
  border-top: none;
  transition: all 150ms linear;
}

.card-team {
  border: none;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.card-team .card-team-header {
  background-color: transparent;
  border-bottom: none;
  padding: 25px;
}

.card-team .card-team-header h5 {
  margin-bottom: 0;
  color: #222;
  font-size: 14px;
  font-weight: 600;
  display: inline-block;
  margin-right: 10px;
  line-height: 1.4;
}

.card-team .card-team-header+.card-team-block, .card-team .card-team-header+.card-team-block-big {
  padding-top: 0;
}

.user-card .card-team-block {
  text-align: center;
}

.user-card .card-team-block p {
  text-align: left;
}

.user-card .card-team-block span {
  text-align: left;
  font-weight: bold;
}

.card-team .card-team-block {
  padding: 25px;
}

.user-card .card-team-block .user-image {
  position: relative;
  margin: 0 auto;
  display: inline-block;
  padding: 5px;
  width: 110px;
  height: 110px;
}

.user-card .card-team-block .user-image img {
  z-index: 20;
  position: absolute;
  top: 5px;
  left: 5px;
  width: 100px;
  height: 100px;
}

.img-radius {
  border-radius: 50%;
}

.f-w-600 {
  font-weight: 600;
}

.m-b-10 {
  margin-bottom: 10px;
}

.m-t-25 {
  margin-top: 25px;
}

.m-t-15 {
  margin-top: 15px;
}

.card-team .card-team-block p {
  line-height: 1.4;
}

.text-muted {
  color: #919aa3!important;
}

.user-card .card-team-block .activity-leval li.active {
  background-color: #2ed8b6;
}

.user-card .card-team-block .activity-leval li {
  display: inline-block;
  width: 15%;
  height: 4px;
  margin: 0 3px;
  background-color: #ccc;
}

.user-card .card-team-block .counter-block {
  color: #fff;
}

.bg-c-blue {
  background: linear-gradient(45deg,#4099ff,#73b4ff);
}

.bg-c-green {
  background: linear-gradient(45deg,#2ed8b6,#59e0c5);
}

.bg-c-yellow {
  background: linear-gradient(45deg,#FFB64D,#ffcb80);
}

.bg-c-pink {
  background: linear-gradient(45deg,#FF5370,#ff869a);
}

.m-t-10 {
  margin-top: 10px;
}

.p-20 {
  padding: 20px;
}

.user-card .card-team-block .user-social-link i {
  font-size: 30px;
}

.text-facebook {
  color: #3B5997;
}

.text-twitter {
  color: #42C0FB;
}

.text-dribbble {
  color: #EC4A89;
}

.user-card .card-team-block .user-image:before {
  bottom: 0;
}

.user-card .card-team-block .user-image:after, .user-card .card-team-block .user-image:before {
  content: "";
  width: 100%;
  height: 48%;
  position: absolute;
  left: 0;
  z-index: 10;
}

.user-card .card-team-block .user-image:after {
  top: 0;
}

.user-card .card-team-block .user-image:after, .user-card .card-team-block .user-image:before {
  content: "";
  width: 100%;
  height: 48%;
  position: absolute;
  left: 0;
  z-index: 10;
}
