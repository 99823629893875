.text-bg h1 {
     color: #fff;
     font-size: 60px;
     line-height: 56px;
     padding-bottom: 25px;
     font-weight: bold;
}

.text-bg span {
     color: #fdd430;
     font-size: 40px;
     line-height: 35px;
     font-weight: bold;
}

.text-bg p {
     color: #fff;
     font-size: 17px;
     line-height: 28px;
     padding: 40px 0;
}

.text-img figure {
     margin: 0px;
}

.text-img figure img {
     width: 100%;
}

.titlepage {
     padding: 0;

}

.titlepage img {
     max-width: 100%;
}

.titlepage h2 {
     font-size: 32px;
     font-weight: bold;
     text-transform: uppercase;
     text-align: center;
     position: relative;
     color: #37517e;
     margin-bottom: 20px;
}

/** infoservices - usage in more than one section**/

.infoservices {
     margin-top: 5px;
     padding: 5px 0;
}

.infoservices .infoservices-box {
     text-align: center;
     background-color: transparent;
     border-radius: 20px;
     border: #cecfd7 solid 2px;
     padding: 33px;
     width: 100%;
     height: 100%;
     box-sizing: border-box;
}

.infoservices .infoservices-box ul {
     line-height: 1.1rem;
}

.infoservices .infoservices-box li {
     background-color: transparent;
     border-radius: 20px;
     margin-top: 10px;
     width: 100%;
     height: 100%;
     list-style: none;
}

.infoservices .infoservices-box p {
     text-align: justify;
}

.infoservices .infoservices-box h3 {
     padding: 5px 0 5px 0;
     font-size: 22px;
     line-height: 20px;
     color: #090808;
     font-weight: 500;
     display: block;
}

/** Tecnologies**/

.tech {
  margin-top: 75px;
}

.tech img {
  max-width: 45%;
  transition: all 0.4s ease-in-out;
  display: inline-block;
  filter: grayscale(100);
}

.tech img:hover {
  filter: none;
  transform: scale(1.1);
}

@media (max-width: 1768px) {
  .tech img {
    max-width: 40%;
  }
}

/** Why**/
.why {
     padding: 5px 0;
     margin-top: 5px;
     margin-bottom: 5x;
     vertical-align: middle;
}

.why .titlepage {
     text-align: center;
     padding: 10px 0 0 0;
}

.why .titlepage h2 {
     margin: 15px 0;
     padding: 5px 0;
}

.web_why {
     vertical-align: middle;
}

.web_why figure {
     margin: 0;
     align-items: right;
}

.web_why h3 {
     color: #0c0f38;
     font-size: 20px;
     font-weight: bold;
     text-transform: uppercase;
     line-height: 20px;
     padding: 10px 10px 0 10px;
     margin: 15px 0 0;
     text-align: justify;
}

.web_why p {
     color: #0c0f38;
     padding: 10px;
     margin: 0;
     text-align: justify;
}

.web_why a {
     font-size: 16px;
     background-color: #000;
     color: #fff;
     padding: 10px 0px;
     width: 100%;
     max-width: 190px;
     text-align: center;
     display: inline-block;
     text-transform: uppercase;
     font-weight: 400;
     margin-top: 10px;
}

.web_why a:hover {
     background-color: #0c0f38;
     color: #fff;
}

/** service**/

.service {
     padding: 15px 0;
}

.service .titlepage {
     text-align: center;
}

.service .titlepage h2 {
     margin: 15px 0;
     padding: 5px 0;
}



.service .service-box h3 {
     font-size: 20px;
     line-height: 25px;
     display: block;
     font-weight: 500;
}

.service .service-box li {
     font-size: 1.1rem;
     line-height: 40px;
     list-style: none;
}

/**type-page**/

.type-page {
  padding: 15px 0 0;
}

.type-page .titlepage {
     text-align: center;
}

.type-page .titlepage h2 {
     padding: 15px 0 0;
     margin: 5px 0 0;
}

.type-page .titlepage p {
     color: #090808;
}

.type-page .type-page-box h3 {
     padding: 5px 0 5px 0;
     font-size: 22px;
     line-height: 20px;
     color: #090808;
     font-weight: 500;
     display: block;
}

.type-page .type-page-box {
     text-align: justify;
     background-color: transparent;
     border-radius: 20px;
     padding: 10px;
     transition: ease-in all 0.5s;
     margin-bottom: 10px;
     word-wrap: break-word;
}

.type-page-box p {
     margin: 32px 0;
     line-height: 24px;
     color: #090808;
     display: block;
}

/*
renovate
*/

.renovate .titlepage {
     text-align: center;
}

.renovate .titlepage h2 {
     padding: 10px 0;
     margin: 10px 0;
}

.renovate .titlepage p {
     color: #090808;
}

.renovate .renovate-box h3 {
     padding: 5px 0 5px 0;
     font-size: 22px;
     line-height: 20px;
     color: #090808;
     font-weight: 500;
     display: block;
}

.renovate .renovate-box {
     text-align: justify;
     background-color: transparent;
     border-radius: 20px;
     padding: 20px 0;
     margin-bottom: 10px;
     word-wrap: break-word;
}

.renovate-box p {
     margin: 32px 0;
     line-height: 24px;
     color: #090808;
     display: block;
}

.renovate .card-header h3 {
     text-align: center;
}

.renovate .card .card-header {
     padding: 12px;
}


/*
metrify
*/

.metrify .titlepage {
     text-align: center;
}

.metrify .titlepage h2 {
     padding: 10px 0;
     margin: 10px 0;
}

.metrify .titlepage p {
     color: #090808;
}

.metrify .metrify-box h3 {
     padding: 5px 0 5px 0;
     font-size: 22px;
     line-height: 20px;
     color: #090808;
     font-weight: 500;
     display: block;
}

.metrify .metrify-box {
     text-align: justify;
     background-color: transparent;
     border-radius: 20px;
     padding: 10px;
     transition: ease-in all 0.5s;
     margin-bottom: 10px;
     word-wrap: break-word;
}

.metrify-box p {
     margin: 32px 0;
     line-height: 24px;
     color: #090808;
     display: block;
}

.metrify .card-header h3 {
     text-align: center;
}

.metrify .card .card-header {
     padding: 12px;
}

.metrify .card {
     background-color:#f3f5fa;
}

/**hosting**/
.hosting .titlepage h2 {
     padding: 10px 0;
     margin: 10px 0;
}

/**questions*/
.questions .titlepage h2 {
     padding: 10px 0;
     margin: 10px 0;
}

/**differentials**/
#differentials .row {
  padding-top: 10px;
  margin: 10px 0;
}

/*
CARDS
*/

.web-section-title {
  text-align: center;
  padding-bottom: 0;
}

.web-section-title h3 {
  font-size: 20px;
  text-transform: uppercase;
  color: #37517e;
  padding-top: 20px;
}
